<template>
  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-md my-3 fixed-start ms-3 bg-white overflow-hidden">
    <div class="bg-primary px-2 py-4 pb-2 text-white">
      <div class="sidenav-header mb-2">
        <NuxtLink class="m-0 d-flex flex-wrap justify-content-center" to="/">
          <img :src="runtimeConfig.public.logo" class="w-75" alt="Logo Image">
        </NuxtLink>
      </div>
      <hr class="horizontal dark">
      <div v-if="admin" class="d-flex flex-wrap align-items-center justify-content-between ps-3 ms-1">
        <div class="f-small"><Icon icon="fa-user" /> {{ admin.firstname }} {{ admin.lastname }}</div>
        <ButtonMore>
          <a @click="$emit('openCreateOrder')" v-if="shop && client && authStore.hasPermission(['Buchungen Bearbeiten'])"><Icon icon="fa-house" /> Neue Buchung in</a>
          <ClientOnly>
            <a @click="setLightMode" v-if="colorMode.preference !== 'light'"><Icon icon="fa-sun" /> Helles Design</a>
            <a @click="setDarkMode" v-else><Icon icon="fa-moon" /> Dunkles Design</a>
          </ClientOnly>
          <a @click="$emit('openCookies')"><Icon icon="fa-left-right" /> Wechseln</a>
          <a @click="tseModal.open()"><Icon icon="fa-cash-register" /> Kassen</a>
          <a @click="signOut({ callbackUrl: '/login' });"><Icon icon="fa-right-from-bracket" /> Logout</a>
        </ButtonMore>
      </div>
      <div v-if="fiskalyClient" class="d-flex align-items-center justify-content-between ps-3 ms-1 f-small">
        <div><Icon icon="fa-cash-register" /> {{ fiskalyClient.name }}</div>
        <i @click="updateFiskalyClient(null)" class="fa fa-right-from-bracket hover-2" />
      </div>
      <div v-if="shop && client" class="d-flex justify-content-between ps-3 ms-1">
        <div class="mt-2 mb-2 f-small">
          <Icon icon="fa-house" /> {{ client?.name }} {{ shop?.name }}
        </div>
        <div class="d-flex align-items-center">
          <i @click="recentOrdersModal.open()" v-if="shop && client && authStore.hasPermission(['Buchungen Bearbeiten'])" class="fa fa-clock-rotate-left hover-2" />
          <i @click="createOrder(shop.id, client.id)" v-if="shop && client && authStore.hasPermission(['Buchungen Bearbeiten'])" class="fa fa-plus hover-2" />
        </div>
      </div>
    </div>
    <div class="position-relative">
      <SidenavList />
    </div>
  </aside>
</template>

<script setup>
import SidenavList from "./SidenavList.vue";
import { getClient, getShop } from "~/composables/admin";
import { useAuthStore } from "~/stores/AuthStore";
import { useOrder } from "~/composables/useOrder";
import { useTSE } from "~/composables/useTSE";

defineEmits([ "openCookies", "openCreateOrder" ]);
const { globalStore, runtimeConfig } = useNuxtApp();
const { data: admin, signOut } = useAuth();
const { createOrder } = useOrder();
const { tseModal, fiskalyClient, updateFiskalyClient } = useTSE();
const client = await getClient();
const shop = await getShop();
const recentOrdersModal = useState("recent_orders_modal");
const authStore = useAuthStore();

const colorMode = useColorMode();

function setLightMode() {
  globalStore.isLoading = true;
  colorMode.preference = "light";
  if (process.client) {
    window.location.reload();
  }
}

function setDarkMode() {
  globalStore.isLoading = true;
  colorMode.preference = "dark";
  if (process.client) {
    window.location.reload();
  }
}
</script>
