<template>
  <div class="py-3">
    <DataTable @row-click="redirectToOrder" id="myOrdersTable" row-id="id" :ajax="`${useRuntimeConfig().public.apiURL}/admins/${admin.id}/recent-orders`" :columns="dataTableHeader" :order="[0, 'desc']" />
  </div>
</template>

<script setup>
const { router, format } = useNuxtApp();
const { data: admin } = useAuth();
const recentOrdersModal = useState("recent_orders_modal");

const dataTableHeader = [
  { data: "created_at", title: "Buchung", render(data) { return format.dateFormat(data, "DD.MM.YY HH:mm"); } },
  { data: "events", title: "Termin" },
  { data: "order_number", title: "Nummer" },
  { data: "shop_name", title: "Shop", name: "shop.name" },
  { data: "client_name", title: "Mandant", name: "client.name" },
  { data: "payment_name", title: "Zahlungsart", name: "payment.name" },
  { data: "shipping_name", title: "Versandart", name: "shipping_method.name" },
  { data: "user_name", title: "Kunde" },
  { data: "orderDataSum", title: "Umsatz (€)", searchable: false, render(data) { return data ? format.currencyFormat(data) : ""; } },
];

function redirectToOrder(row) {
  recentOrdersModal.value.close();
  router.push(`/orders/${row.getAttribute("id")}`);
}
</script>
