<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div class="text-sm text-center copyright text-muted text-lg-start">
            ©
            {{ new Date().getFullYear() }}, made by
            Qwellcode Solutions GmbH & Co. KG
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
