<template>
  <li class="nav-item">
    <NuxtLink class="nav-link" :class="{ 'router-link-exact-active': getRoute === to }" :to="to">
      <div v-if="icon" class="icon icon-sm text-center d-flex align-items-center justify-content-center">
        <Icon :icon="icon" />
      </div>
      {{ text }}
    </NuxtLink>
  </li>
</template>

<script setup>
defineProps({
  to: {
    type: [ Object, String ],
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  icon: { type: String },
});

const getRoute = computed(() => {
  const route = useRoute();
  return `/${route.path.split("/")[1]}`;
});
</script>
