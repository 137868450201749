<template>
  <a
    @click="expand"
    v-bind="$attrs"
    :id="navText.toLowerCase()"
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :href="collapse ? `#${collapseRef}` : collapseRef"
    :aria-controls="collapseRef"
    :aria-expanded="isExpanded"
    class="nav-link"
  >
    <div class="icon icon-sm text-center d-flex align-items-center justify-content-center">
      <slot name="icon" />
    </div>
    <span class="nav-link-text">{{ navText }}</span>
  </a>
  <div :class="isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list" />
  </div>
</template>

<script setup>
const props = defineProps({
  collapseRef: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
  collapse: {
    type: Boolean,
    default: true,
  },
  isExpanded: {
    type: Boolean,
    default: true,
  },
});

const isExpanded = ref(props.isExpanded);


function expand(){

  isExpanded.value = !isExpanded.value
}

function toLowerCase(text) {
  return text.toLowerCase();
}
</script>
